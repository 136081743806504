



















import { Component, Vue } from "vue-property-decorator";
import Modal from "../../commons/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import { api } from "../../../api";
import { EventBus } from "../../../enums";
import { eventBus } from "../../../utils/eventBus";
import {
  CreateMaintenanceInput,
  Maintenance,
  MaintenanceTypeEnum,
} from "../../../types/gqlGeneratedPrivate";
import MaintenanceForm from "./MaintenanceForm.vue";
import { apiClient } from "../../../utils/apiClient";
import { alerts } from "../../../utils";

@Component({
  components: {
    Button,
    Modal,
    MaintenanceForm,
    TextField,
    ValidationObserver,
  },
})
export default class MaintenanceCreateModal extends Vue {
  isOpen = false;
  isSending = false;

  deviceId = 0;

  formData: CreateMaintenanceInput | null = null;

  created(): void {
    eventBus.$on(EventBus.MaintenanceCreateModal, (device: Maintenance) => {
      this.formData = {
        description: "",
        responsiblePersonId: 0,
        plannedDate: "",
        deviceId: device.id,
        type: Object.values(MaintenanceTypeEnum)[0],
      };
      this.isOpen = true;
      this.initForm();
    });
  }

  async onSubmit(): Promise<void> {
    const valid = await (this.$refs.form as any).validate();
    if (valid && this.formData) {
      await this.sendForm(this.formData);
    }
  }

  async sendForm(formData: CreateMaintenanceInput): Promise<void> {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      Maintenance,
      { input: CreateMaintenanceInput }
    >({
      ...api.maintenances.createMaintenance,
      variables: {
        input: {
          description: formData.description,
          responsiblePersonId: formData.responsiblePersonId,
          plannedDate: formData.plannedDate,
          deviceId: formData.deviceId,
          type: formData.type,
        },
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("CREATE_MAINTENANCE", "Vytvořeno");
    }
    this.isOpen = false;
    this.isSending = false;
  }

  initForm(): void {
    this.isSending = false;
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }

  cancel(): void {
    this.isOpen = false;
    this.initForm();
  }
}
