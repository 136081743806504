









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Modal from "../../commons/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import {
  Admin,
  AdminListQuery,
  AdminListQueryVariables,
  UpdateMaintenanceInput,
} from "../../../types/gqlGeneratedPrivate";
import SelectBox from "../../commons/inputsAndControls/SelectBox.vue";
import Textarea from "../../commons/inputsAndControls/Textarea.vue";
import DateField from "../../commons/inputsAndControls/DateField.vue";
import { apiClient } from "../../../utils";
import { api } from "../../../api";
import { maintenanceTypes } from "./maintenanceTypes";

@Component({
  components: {
    DateField,
    Textarea,
    SelectBox,
    Modal,
    ValidationObserver,
    Button,
    TextField,
  },
})
export default class MaintenanceForm extends Vue {
  @Prop({ type: Object, required: true })
  value!: UpdateMaintenanceInput;

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;

  @Watch("value", { immediate: true, deep: true })
  onValueChanged(val: UpdateMaintenanceInput) {
    this.data = val;
  }

  data: UpdateMaintenanceInput | null = null;
  admins: Admin[] = [];

  get maintenanceTypes() {
    return maintenanceTypes();
  }

  async validate(): Promise<boolean> {
    return (this.$refs.observer as any).validate();
  }

  reset(): void {
    (this.$refs.observer as any).reset();
  }

  async laborers(): Promise<void> {
    const result = await apiClient.callGraphqlPrivate<
      AdminListQuery,
      AdminListQueryVariables
    >({
      ...api.admins.adminList,
    });
    if (result.data?.admins) {
      this.admins = result.data.admins.items;
    }
  }

  async created() {
    await this.laborers();
  }
}
