

































































import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import { Device, MutationCopyDeviceArgs } from "../types/gqlGeneratedPrivate";
import Table from "../components/commons/tables/Table.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import { alerts, apiClient } from "../utils";
import { api } from "../api";
import CompletedMaintenancesTable from "../components/app/maintenances/CompletedMaintenancesTable.vue";
import DeviceUpdateModal from "../components/app/devices/DeviceUpdateModal.vue";
import { eventBus } from "../utils/eventBus";
import { EventBus, Routes } from "../enums";
import RegularMaintenancesTable from "../components/app/regularMaintenances/RegularMaintenancesTable.vue";
import MaintenanceCreateModal from "../components/app/maintenances/MaintenanceCreateModal.vue";

@Component({
  components: {
    MaintenanceCreateModal,
    RegularMaintenancesTable,
    DeviceUpdateModal,
    CompletedMaintenancesTable,
    Button,
    Table,
    RoundButton,
    ActionsWrapper,
    TableHeader,
    AppLayout,
  },
})
export default class DeviceDetailPage extends Vue {
  Routes = Routes;
  device: Device | null = null;

  updateItem(): void {
    if (this.device) {
      eventBus.$emit(EventBus.DeviceUpdateModal, this.device);
    }
  }
  async cloneItem(): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      {
        copyDevice: {
          name: string;
        };
      },
      MutationCopyDeviceArgs
    >({
      ...api.device.cloneDevice,
      variables: {
        id: parseInt(this.$route.params.id),
      },
    });
    if (data) {
      alerts.addSuccessAlert(
        "CLONE_DEVICE",
        `Zařízení ${data.copyDevice.name} naklonováno!`
      );
    }
  }

  addService(): void {
    eventBus.$emit(EventBus.MaintenanceCreateModal, this.device);
  }

  async fetchDevice() {
    return await apiClient.callGraphqlPrivate<
      { device: Device },
      { id: number }
    >({
      ...api.device.fetchDevice,
      variables: {
        id: parseInt(this.$route.params.id),
      },
    });
  }

  async created() {
    const response = await this.fetchDevice();
    if (response.data) {
      this.device = response.data.device;
    }
    eventBus.$on(EventBus.DeviceUpdatedEvent, async (hasUpdated: boolean) => {
      if (hasUpdated) {
        const response = await this.fetchDevice();
        alerts.addSuccessAlert("EDIT_DEVICE", `Zařízení upraveno!`);
        if (response.data) {
          this.device = response.data.device;
        }
      }
    });
  }
}
